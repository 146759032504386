import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout, { gradientPicker } from "../components/layout"
import SEO from "../components/seo"
import Posts from "../components/posts"

import LogoOnly from "../../static/images/DAIQUIRI_logo_only-01.svg"

const BlogPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      featuredAllMarkdownRemark: allMarkdownRemark(
        filter: {
          frontmatter: { featured: { ne: null } }
          fileAbsolutePath: { regex: "/posts//" }
        }
        sort: { fields: frontmatter___featured, order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date
              title
              featured
              category
              summary
            }
            excerpt(truncate: true)
            timeToRead
          }
        }
      }
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { fileAbsolutePath: { regex: "/posts/d_guides//" } }
        limit: 8
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date
              title
              featured
              category
              summary
            }
            excerpt(truncate: true)
            timeToRead
          }
        }
      }
      allFile(filter: { ext: { ne: ".md" }, dir: { regex: "//posts/" } }) {
        nodes {
          publicURL
          name
        }
      }
    }
  `)
  const allEdges = queryResult.allMarkdownRemark.edges
  const featuredEdges = queryResult.featuredAllMarkdownRemark.edges
  const allNonMdFilesInRepo = queryResult.allFile.nodes
  const imageMap = allNonMdFilesInRepo.reduce((map, node) => {
    map[node.name] = node.publicURL
    return map
  }, {})

  const [featuredIndex, setFeaturedIndex] = React.useState(0)
  const [featuredTimeout, setFeaturedTimeout] = React.useState(-1) // wrapper used for lazy initialization, then empty function returned
  const slideTimeout = 15_000
  const nextFeaturedPost = () => {
    clearTimeout(featuredTimeout)
    setFeaturedIndex(f => (f + 1) % featuredEdges.length)
    setFeaturedTimeout(() => setTimeout(nextFeaturedPost, slideTimeout))
  }

  const previousFeaturedPost = () => {
    clearTimeout(featuredTimeout)
    setFeaturedIndex(f => (f + featuredEdges.length - 1) % featuredEdges.length)
    setFeaturedTimeout(() => setTimeout(nextFeaturedPost, slideTimeout))
  }

  React.useEffect(() => {
    setFeaturedTimeout(() => setTimeout(nextFeaturedPost, slideTimeout))
  }, [])

  // const [categoryFilter, setCategoryFilter] = React.useState('');

  return (
    <Layout>
      <SEO title="Dev Portal" />
      {featuredEdges.length > 0 && (
        <div className="container pt-2">
          <div className="row justify-items-center featured-row">
            <div className="col-md-9 px-6 d-flex d-md-block flex-column text-container">
              <div className="row align-items-md-end content-header pt-3 pt-md-0">
                <div className="col text-white">
                  <div className="h1 w-100 text-primary mt-2">
                    {featuredEdges[featuredIndex].node.frontmatter.title}
                  </div>{" "}
                  <div className="h6 fw-regular w-100 text-normal">
                    {`${
                      featuredEdges[featuredIndex].node.frontmatter.date
                    }  -  ${featuredEdges[featuredIndex].node.timeToRead} min${
                      featuredEdges[featuredIndex].node.timeToRead > 1
                        ? "s"
                        : ""
                    } read  -  ${
                      featuredEdges[featuredIndex].node.frontmatter.category
                    }`}
                  </div>
                </div>
              </div>
              <div className="row content-description flex-fill pt-2">
                <div className="col-12 text-normal w-100">
                  <div>
                    {featuredEdges[featuredIndex].node.frontmatter.summary ||
                      featuredEdges[featuredIndex].node.excerpt}
                  </div>
                  <div className="mt-4 text-center text-md-left">
                    <Link
                      to={`/posts/${featuredEdges[featuredIndex].node.fields.slug}`}
                    >
                      {" "}
                      <button className="btn btn-sm btn-rounded btn-outline-primary">
                        Read More
                      </button>{" "}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row content-footer py-2 py-md-0">
                {featuredEdges.length > 1 && (
                  <div className="col-12 align-self-middle">
                    <button
                      className="btn btn-primary btn-sm btn-circle mr-1 text-light shadow-none"
                      onClick={previousFeaturedPost}
                    >
                      &lt;
                    </button>
                    <button
                      className="btn btn-primary btn-sm btn-circle text-light shadow-none"
                      onClick={nextFeaturedPost}
                    >
                      &gt;
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="d-none d-md-flex col-md-3 px-md-0 image-container">
              <div className="shadow rounded d-inline-block position-absolute p-right p-top">
                <span className="badge badge-primary h6 m-0 condensed fw-bold">
                  FEATURED POST
                </span>
              </div>
              <div className="d-inline-block position-absolute p-bottom p-left p-right p-top text-center d-flex justify-content-center flex-column">
                <LogoOnly style={{ height: 55 }} />
              </div>
              <div
                className="d-inline-block position-absolute p-left p-bottom p-right d-flex flex-column justify-content-center text-center"
                style={{ height: 85 }}
              >
                <h3 className="m-0 text-gray" style={{ letterSpacing: 2 }}>
                  {featuredEdges[
                    featuredIndex
                  ].node.frontmatter.category.toUpperCase()}
                </h3>
              </div>
              <div
                className={`d-inline-block position-absolute p-left p-bottom p-right rounded ${gradientPicker(
                  featuredEdges[featuredIndex].node.frontmatter.category
                )}`}
                style={{ height: 4 }}
              ></div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="h2 my-3 px-2 px-sm-0">
          Recent Posts
          <Link to={`/guides`} className="ml-4">
            <button className="btn btn-primary btn-rounded shadow-none float-s">
              View All
            </button>
          </Link>
        </div>
        <Posts edges={allEdges} imageMap={imageMap} defaultImage="cat-guides" />
      </div>
    </Layout>
  )
}

export default BlogPage
